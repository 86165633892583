import { useCallback, useState } from "react";

const API_URL = process.env.REACT_APP_API_URL || "https://citadel.ix.tc:6969/shortix";

(async () => {
    const path = window.location.pathname.substring(1);
    if(path.length === 8) {
        const res = await fetch(`${API_URL}?id=${path}`);
        if(res.status !== 200)
            window.location.href = "/";
        else
            window.location.href = await res.text();
    }
    else if(path.length > 0)
        window.location.href = "/";
})();

const validateUrl = (url: string): boolean => /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*$)/.test(url);

function App() {
    const [url, setUrl] = useState("");
    const [shortId, setShortId] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const shortenUrl = useCallback(async () => {
        setLoading(true);

        const res = await fetch(API_URL, {
            body: JSON.stringify({url}),
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        });
        
        if(res.status === 200) {
            const id = await res.text();
            setShortId(id);
            navigator.clipboard.writeText(`https://short.ix.tc/${id}`)
        }
        else
            alert("Something went wrong shortening your URL. Please try again later.");

        setLoading(false);
    }, [url]);

    const onUrlKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "Enter")
            shortenUrl();
    }, [shortenUrl]);

    const error: string | null = (url.length > 0 && !validateUrl(url))
        ? "The entered URL is not valid. Note that URLs must start with http:// or https://"
        : null;
    const inputStyle: React.CSSProperties = error
        ? {borderColor: "red"}
        : {};

    const label = shortId === null
        ? <p>Fast, easy to use, anonymous and free.</p>
        : <p>Your shortened URL (<a href={`https://short.ix.tc/${shortId}`}>{`https://short.ix.tc/${shortId}`}</a>) has been copied to your clipboard.</p>

    return (
        <div className="container">
            {loading && (
                <div className="loader-container">
                    <span className="loader"/>
                </div>
            )}
            <h1>Shortix</h1>
            <input
                type="text"
                value={url}
                placeholder="Some URL..."
                onChange={(e) => setUrl(e.target.value)}
                style={inputStyle}
                onKeyDown={onUrlKeyDown}
            />
            {error && (
                <p className="error">{error}</p>
            )}
            <button
                onClick={shortenUrl}
                disabled={url.length === 0 || !validateUrl(url)}
            >
                Shorten URL
            </button>
            {label}
        </div>
    );
}

export default App;
